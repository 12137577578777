// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;

import Rails from "@rails/ujs"
Rails.start()

import Turbolinks from "turbolinks"

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()
import "channels"
require("@popperjs/core")

import  Swiper, { Navigation, Controller} from 'swiper'
Swiper.use([Navigation, Controller]);

Turbolinks.start()


// window.Swiper = Swiper
import "bootstrap"
import "../stylesheets/application"
import 'bootstrap-icons/font/bootstrap-icons.css'



// import 'bootstrap/dist/js/bootstrap'
// import "bootstrap/dist/css/bootstrap";
// Import the specific modules you may need (Modal, Alert, etc)
//import { Tooltip, Popover, Modal } from "bootstrap"

// The stylesheet location we created earlier
//require("../stylesheets/application.scss")

// If you're using Turbolinks. Otherwise simply use: jQuery(function () {
document.addEventListener("turbolinks:load", () => {
    // Both of these are from the Bootstrap 5 docs
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    })

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
        return new Popover(popoverTriggerEl)
    })
})

document.addEventListener("DOMContentLoaded", function(){

    var swiper_menu = new Swiper('.swiper-menu', {
        slidesPerView: 2,
        spaceBetween: 1,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            350: {slidesPerView: 3,       spaceBetween: 1},
            500: {slidesPerView: 4,       spaceBetween: 1},
            1000:{slidesPerView: 4,       spaceBetween: 1},
            1930:{slidesPerView: 5,       spaceBetween: 1},
            //2000:{slidesPerView: 6,       spaceBetween: 20},
        },
    });

    var nCateg = gon.number_categ;
    var swiper_categs = [];
    for (let i = 0; i < nCateg; i++) {
        var chTemp = 'swiper-categ-'+i
        swiper_categs[i] = new Swiper('.swiper-categ-'+i, {
             slidesPerView: 1,
             allowTouchMove: false,
            // slidesPerColumn: 3,  
            // slidesPerColumnFill: 'column',
            // grid: {
            //     rows: 4,
            //     columns: 3,
            // },
            grid: {
                rows: 10,
              },
            navigation: {
                nextEl: '#swiper-button-next-id-'+i,
                prevEl: '#swiper-button-prev-id-'+i,
            },
            breakpoints: {
                450: {slidesPerView: 3,       spaceBetween: 10},
                550:{slidesPerView: 4,       spaceBetween: 10},
                1600:{slidesPerView: 5,       spaceBetween: 10},
                 1900:{slidesPerView: 6,       spaceBetween: 10},
            },            
        });
      };


    //   Modal product
      $(document).on('show.bs.modal','#modal-window', function () {
        
        var swiper_product_list = new Swiper('.swiper-product-list', {
            slidesPerView: 4,
            //freeMode: true,
            // centeredSlides: true,
            slideToClickedSlide: true,
            navigation: {
                nextEl: '#swiper-button-next-product-list',
                prevEl: '#swiper-button-prev-product-list',
            },
        });

        var swiper_product_top = new Swiper('.swiper-product-top', {
            slidesPerView: 1,
            zoom: true,
            navigation: {
                nextEl: '#swiper-button-next-product-top',
                prevEl: '#swiper-button-prev-product-top',
            },
        });

        swiper_product_list.controller.control = swiper_product_top;
        swiper_product_top.controller.control = swiper_product_list;

      })

});




$(function(){
    //the shrinkHeader variable is where you tell the scroll effect to start.
    var shrinkHeader = 200;
     $(window).scroll(function() {
       var scroll = getCurrentScroll();
         if ( scroll >= shrinkHeader ) {
              $('.img_slider_menu').addClass('imgslider-smaller');
              $('.swiper-slide-menu').addClass('swiper-slide-menu-smaller');
            //   alert("apres smaller");
           }
           else {
               $('.img_slider_menu').removeClass('imgslider-smaller');
               $('.swiper-slide-menu').removeClass('swiper-slide-menu-smaller');
            //    alert("apres plus smaller");
           }
     });
   function getCurrentScroll() {
       return window.pageYOffset || document.documentElement.scrollTop;
       }
   });

   

   





require("trix")
require("@rails/actiontext")